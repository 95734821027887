import React from 'react';
import './SearchBar.css';

const SearchBar = ({ onSearch }) => {
    const handleChange = (event) => {
        onSearch(event.target.value);
    };

    return (
        <input
            className="search-bar"
            type="text"
            placeholder="Search by name"
            onChange={handleChange}
        />
    );
};

export default SearchBar;
