import React, { useEffect, useRef, useState } from 'react';
import { BsInfoCircle, BsX } from 'react-icons/bs'; // Import Bootstrap icons
import * as d3 from 'd3';
import './EpsteinNetworkDiagram.css';

const colorDefault = "rgba(0,148,198,0.3)";
const colorHighlight = "#FC440F";
const colorSecondary = "lightcoral";
const colorEpstein = "#054A29";
const colorFirstDegree = "#8AC926"; // Customize the color for first-degree connections
const colorSecondDegree = "#FFCA3A"; // Customize the color for second-degree connections

const NetworkDiagram = ({ data }) => {
    const svgRef = useRef();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [hoveredName, setHoveredName] = useState("");
    const [hoveredDescription, setHoveredDescription] = useState("");
    const [showInfo, setShowInfo] = useState(false);

    // Define minRadius and maxRadius here
    const minRadius = 5;
    const maxRadius = 8;

    useEffect(() => {
        let nodesData = [];
        let linksData = [];

        // Store all people (links or regular people) with their descriptions
        let peopleDescriptions = {};

        // Central node
        nodesData.push({ id: 'Jeffrey Epstein', group: 0, well_known_score: Infinity, fx: width / 2, fy: height / 2 });

        // Populate nodes, links, and descriptions
        for (let name in data) {
            const description = data[name].first_sentence;

            nodesData.push({
                id: name,
                group: 1,
                well_known_score: data[name].well_known_score,
                description: description
            });

            peopleDescriptions[name] = description;

            linksData.push({ source: 'Jeffrey Epstein', target: name });

            data[name].links.forEach((link, index) => {
                if (!nodesData.find(d => d.id === link)) {
                    nodesData.push({ id: link, group: 2, well_known_score: 0 });
                }

                const linkDescription = data[name].link_first_sentences[index];
                linksData.push({
                    source: name,
                    target: link,
                    description: linkDescription
                });

                peopleDescriptions[link] = linkDescription;
            });
        }

        // Filter to only connected nodes
        const connectedNodes = nodesData.filter(node => {
            const sourceConnections = linksData.filter(link => link.source === node.id).length;
            const targetConnections = linksData.filter(link => link.target === node.id).length;
            return sourceConnections + targetConnections > 1;
        });

        const connectedLinks = linksData.filter(link =>
            connectedNodes.find(node => node.id === link.source) &&
            connectedNodes.find(node => node.id === link.target)
        );

        d3.select(svgRef.current).selectAll("*").remove();

        const svg = d3.select(svgRef.current);
        const svgWidth = +svg.attr("width");
        const svgHeight = +svg.attr("height");

        const color = d3.scaleOrdinal(d3.schemeCategory10);

        const simulation = d3.forceSimulation()
            .force("link", d3.forceLink().id(function (d) { return d.id; }).distance(200))
            .force("charge", d3.forceManyBody().strength(-200))
            .force("center", d3.forceCenter(width / 2, height / 2));

        const zoom = d3.zoom()
            .scaleExtent([0.1, 10])
            .on("zoom", zoomed);

        svg.call(zoom);

        const container = svg.append("g");

        const link = container.append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(connectedLinks)
            .enter().append("line")
            .attr("stroke", colorDefault)
            .attr("stroke-width", 0.5);

        const radiusScale = d3.scaleLinear()
            .domain([0, d3.max(connectedNodes, d => d.group === 1 ? d.well_known_score + 1 : 0)])
            .range([minRadius, maxRadius]);

        const node = container.append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            .data(connectedNodes)
            .enter().append("circle")
            .attr("r", d => d.id === "Jeffrey Epstein" ? radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.5 : radiusScale(d.group === 1 ? d.well_known_score + 1 : 0))
            .attr("fill", function (d) {
                if (d.id === "Jeffrey Epstein") {
                    return colorEpstein;
                } else if (d.group === 1) {
                    return colorFirstDegree; // Set custom color for first-degree connections
                } else if (d.group === 2) {
                    return colorSecondDegree; // Set custom color for second-degree connections
                } else {
                    return color(d.group);
                }
            })
            .on("mouseover", handleMouseOver)
            .on("mouseout", handleMouseOut)
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));

        const labels = container.append("g")
            .attr("class", "labels")
            .selectAll("text")
            .data(connectedNodes)
            .enter().append("text")
            .attr("class", "label")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "hanging")
            .attr("fill", "white")
            .text(function (d) {
                return d.well_known_score > 100000 ? d.id : "";
            });

        simulation
            .nodes(connectedNodes)
            .on("tick", ticked);

        simulation.force("link")
            .links(connectedLinks);

        function ticked() {
            link
                .attr("x1", function (d) {
                    return d.source.x;
                })
                .attr("y1", function (d) {
                    return d.source.y;
                })
                .attr("x2", function (d) {
                    return d.target.x;
                })
                .attr("y2", function (d) {
                    return d.target.y;
                });

            node
                .attr("cx", function (d) {
                    return d.x;
                })
                .attr("cy", function (d) {
                    return d.y;
                });

            labels
                .attr("x", function (d) {
                    return d.x;
                })
                .attr("y", function (d) {
                    return d.y + 10;
                });
        }

        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        function handleMouseOver(event, d) {
            // Customize the hover effect as desired
            setHoveredName(d.id);
            setHoveredDescription(peopleDescriptions[d.id]);

            d3.select(this)
                .attr("r", radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.5)
                .attr("fill", colorHighlight);

            const firstDegreeConnections = connectedLinks.filter(
                (link) => link.source === d || link.target === d
            );

            link
                .filter((link) => firstDegreeConnections.includes(link))
                .attr("stroke", colorHighlight)
                .attr("stroke-width", 1.5);

            node
                .filter((node) =>
                    firstDegreeConnections.some(
                        (link) =>
                            link.source === d && link.target === node ||
                            link.target === d && link.source === node
                    )
                )
                .attr("fill", colorHighlight)
                .attr("r", d => radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.2);

            labels
                .filter((node) => {
                    const isSourceConnected = firstDegreeConnections.some((link) => link.source === d && link.target === node);
                    const isTargetConnected = firstDegreeConnections.some((link) => link.source === node && link.target === d);
                    return node.id === d.id || isSourceConnected || isTargetConnected;
                })
                .text(function (node) {
                    return node.id;
                })
                .style("visibility", "visible");
        }

        function handleMouseOut(event, d) {
            setHoveredName('');
            setHoveredDescription('');

            d3.select(this)
                .attr("r", d => d.id === "Jeffrey Epstein" ? radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.5 : radiusScale(d.group === 1 ? d.well_known_score + 1 : 0))
                .attr("fill", function (d) {
                    if (d.id === "Jeffrey Epstein") {
                        return colorEpstein;
                    } else if (d.group === 1) {
                        return colorFirstDegree;
                    } else if (d.group === 2) {
                        return colorSecondDegree;
                    } else {
                        return color(d.group);
                    }
                });

            link
                .attr("stroke", colorDefault)
                .attr("stroke-width", 0.5);

            labels
                .filter((node) => node.id === d.id && node.well_known_score <= 100000)
                .text("")
                .style("visibility", "hidden");

            node
                .filter((node) =>
                    connectedLinks.some(
                        (link) =>
                            (link.source === d && link.target === node) ||
                            (link.source === node && link.target === d)
                    )
                )
                .attr("fill", function (d) {
                    if (d.id === "Jeffrey Epstein") {
                        return colorEpstein;
                    } else if (d.group === 1) {
                        return colorFirstDegree;
                    } else if (d.group === 2) {
                        return colorSecondDegree;
                    } else {
                        return color(d.group);
                    }
                })
                .attr("r", d => d.id === "Jeffrey Epstein" ? radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.5 : radiusScale(d.group === 1 ? d.well_known_score + 1 : 0));

            labels
                .filter((node) => {
                    const isSourceConnected = connectedLinks.some((link) => link.source === d && link.target === node);
                    const isTargetConnected = connectedLinks.some((link) => link.source === node && link.target === d);
                    return isSourceConnected || isTargetConnected;
                })
                .style("visibility", function (node) {
                    return node.well_known_score > 100000 ? "visible" : "hidden";
                });
        }

        function zoomed(event) {
            container.attr("transform", event.transform);
        }
    }, [data]);

    const handleInfoClick = () => {
        setShowInfo(true);
    };

    const handleCloseClick = () => {
        setShowInfo(false);
    };

    return (
        <div className="network-diagram-div">
            <svg ref={svgRef} width={width} height={height}></svg>
            {hoveredName && (
                <div className="bottom-right-div">
                    <div className="hovered-name">{hoveredName}</div>
                    <div className="hovered-description">{hoveredDescription}</div>
                </div>
            )}
            {showInfo && (
                <div className="info-container">
                    <div className="info-content">
                        <div className="info-header">
                            <h3>Project Information</h3>
                            <button className="close-button" onClick={handleCloseClick}>
                                <BsX />
                            </button>
                        </div>
                        <div className="info-body">
                            <p><strong>This interactive visualization allows you to explore and analyze the connections within Jeffrey Epstein's network. Here's what you need to know and how you can use it:</strong></p>

                            <ol>
                                <li>
                                    <strong>Network Visualization:</strong> The main feature is the network diagram itself. It represents individuals as nodes and their connections as links. Nodes' size and color indicate their importance and connection degrees.
                                </li>
                                <li>
                                    <strong>Node Interaction:</strong> Hover over a node to see additional information. The name and a description (first sentence from their Wikipedia page) will appear. This helps you quickly gather information about specific individuals in the network.
                                </li>
                                <li>
                                    <strong>Highlighting Connections:</strong> Interact with nodes to highlight their connections. When hovering over a node, all its first-degree connections (directly connected individuals) will stand out with a different color and thicker links. This feature helps you understand immediate relationships and connections.
                                </li>
                                <li>
                                    <strong>Zooming and Panning:</strong> You can zoom in and out to examine details or get an overview of the entire network. Use the scroll wheel or touch gestures to zoom. Click and drag the diagram to pan and explore different areas of the network.
                                </li>
                                <li>
                                    <strong>Responsive Design:</strong> The webpage is designed to adapt to different screen sizes and devices. Whether you're on a desktop, laptop, tablet, or smartphone, you can comfortably view and interact with the network diagram.
                                </li>
                            </ol>

                            <p>
                                <em>The Epstein Network Diagram</em> was created using a combination of a D3 network diagram and a Python script. The Python script scrapes data from Wikipedia, retrieving individuals' names, the first sentences of their page, and identifies other individuals mentioned on the page, expanding the network.
                            </p>

                            <p>
                                The scraped data is then used as input for the React component responsible for the network diagram visualization. The React component uses the D3 library to render the network diagram based on the retrieved data. Nodes represent individuals, and links represent connections. The size and color of the nodes indicate importance and connection degrees. Interactions such as hovering over nodes and highlighting connections are enabled to enhance exploration.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="info-icon-container" onClick={handleInfoClick}>
                <BsInfoCircle className="info-icon" />
            </div>
        </div>
    );
};

export default NetworkDiagram;
