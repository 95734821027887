// Importing necessary dependencies and components
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

// Defining the MapChart component
const MapChart = ({ activeStep, essayContent }) => {
    const svgRef = useRef(null);
    const [maxActiveStep, setMaxActiveStep] = useState(activeStep);
    const [currentEventTypes, setCurrentEventTypes] = useState(new Set());

    const colorMap = {
        activePhase: '#FFBF47',
        activePhaseStroke: '#e5a224',
        pastPhase: '#FFEAC2',
        futurePhase: '#fff5e1',
        'Abduction/forced disappearance': '#FF0000',
        'Agreement': '#00FF00',
        'Armed clash': '#0000FF',
        'Arrests': '#FFFF00',
        'Attack': '#1CE6FF',
        'Change to group/activity': '#FF34FF',
        'Disrupted weapons use': '#FF4A46',
        'Grenade': '#008941',
        'Looting/property destruction': '#006FA6',
        'Mob violence': '#ff0000',
        'Other': '#FFDBE5',
        'Peaceful protest': '#7A4900',
        'Protest with intervention': '#0000A6',
        'Remote explosive/landmine/IED': '#63FFAC',
        'Sexual violence': '#B79762',
        'Violent demonstration': '#00ffe0',
    };

    const phaseColorKeys = new Set(['activePhase', 'pastPhase', 'futurePhase']);

    useEffect(() => {
        // Update maxActiveStep if activeStep is greater
        if (activeStep > maxActiveStep) {
            setMaxActiveStep(activeStep);
        }

        const svg = d3.select(svgRef.current);

        // Fetching and processing JSON data
        d3.json('./india_pc_updated.json').then((data) => {
            const projection = d3.geoMercator()
                .center([82, 23])
                .scale(1200)
                .translate([400, 400]);

            const path = d3.geoPath().projection(projection);

            const paths = svg
                .selectAll('path')
                .data(data.features);

            // Adding new paths for each feature
            paths.enter()
                .append('path')
                .attr('d', path)
                .style('fill', '#fff5e1') // Default color before data load and transition

            // Applying transitions and updating colors based on activeStep
            paths
                .transition()
                .duration(50)
                .delay((d) => {
                    const centroid = path.centroid(d);
                    const delay = (700 - centroid[1]) * 5;
                    const randomDelay = Math.random() * 500;
                    return delay + randomDelay;
                })
                .style('fill', (d) => {
                    if (d.properties['Phase'] <= activeStep) {
                        return '#FFEAC2'; // past phase color (Lavender)
                    } else {
                        return '#fff5e1'; // future phase color (White)
                    }
                })
                .style('stroke', (d) => {
                    if (d.properties['Phase'] <= activeStep) {
                        return '#FFEAC2'; // past phase color (Lavender)
                    } else {
                        return '#fff5e1'; // future phase color (White)
                    }
                })
                .transition()
                .duration(1000)
                .delay(1000)
                .style('fill', (d) => {
                    if (d.properties['Phase'] === activeStep) {
                        return '#FFBF47'; // active phase color (Yellow)
                    } else if (d.properties['Phase'] <= activeStep) {
                        return '#FFEAC2'; // past phase color (Lavender)
                    } else {
                        return '#fff5e1'; // future phase color (White)
                    }
                })
                .style('stroke', (d) => {
                    if (d.properties['Phase'] === activeStep) {
                        return '#FFBF47'; // active phase color (Yellow)
                    } else if (d.properties['Phase'] <= activeStep) {
                        return '#FFEAC2'; // past phase color (Lavender)
                    } else {
                        return '#fff5e1'; // future phase color (White)
                    }
                })
                .transition()
                .delay(3000)
                .style('fill', (d) => {
                    if (d.properties['Phase'] === activeStep
                        && essayContent[activeStep].highlightConstituencies
                        && essayContent[activeStep].highlightConstituencies.includes(d.properties['PC_NAME'])) {
                        return '#AA4465'; // special constituency color (Purple)
                    }

                    else if (d.properties['Phase'] === activeStep) {
                        return '#FFBF47'; // active phase color (Yellow)
                    } else if (d.properties['Phase'] <= activeStep) {
                        return '#FFEAC2'; // past phase color (Light Yellow)
                    } else {
                        return '#fff5e1'; // future phase color (White)
                    }
                })
                .style('stroke', (d) => {
                    if (d.properties['Phase'] === activeStep
                        && essayContent[activeStep].highlightConstituencies
                        && essayContent[activeStep].highlightConstituencies.includes(d.properties['PC_NAME'])) {
                        return '#943b57'; // special constituency stroke color (Purple)
                    } else if (d.properties['Phase'] === activeStep) {
                        return '#ecaf3c'; // active phase color (Yellow)
                    } else if (d.properties['Phase'] <= activeStep) {
                        return '#FFEAC2'; // past phase color (Light Yellow)
                    } else {
                        return '#fff5e1'; // future phase color (White)
                    }
                })
                .style('stroke-width', (d) => d.properties['Phase'] === activeStep ? 1 : 1)
                .style('stroke-linejoin', 'round')
                .style('stroke-linecap', 'round');

            d3.csv('./india-election-violence.csv').then((violenceData) => {
                let currentViolenceData = violenceData.filter(v => +v.phase === activeStep);
                currentViolenceData.sort((a, b) => +a.latitude - +b.latitude);

                let currentEventTypes = new Set(currentViolenceData.map(v => v.sub_event_type));
                setCurrentEventTypes(currentEventTypes);

                const circles = svg.selectAll("circle").data(currentViolenceData, d => d.id).join(
                    enter => enter.append("circle")
                        .attr("cx", d => projection([+d.longitude, +d.latitude])[0])
                        .attr("cy", d => projection([+d.longitude, +d.latitude])[1])
                        .attr("r", 0)
                        .attr("fill", d => colorMap[d.sub_event_type])
                        .attr("opacity", 0.5) // Added opacity attribute
                        // .attr("stroke", 'black') // Adding a black stroke
                        // .attr("stroke-width", "1px") // Setting the stroke width
                        .transition()
                        .duration(300)
                        // The delay will be 0 when activeStep is 0 (or 1, depending on your numbering) else it will be as before
                        .delay((d, i) => activeStep === 0 ? 0 : 2000 + 50 * i + Math.random() * 500)
                        .attr("r", "3px"),
                    update => update,
                    exit => exit.transition().duration(100).attr("r", 0).remove()
                );
            });
        });

    }, [activeStep, maxActiveStep, essayContent]);

    // Rendering the MapChart component
    return (
        <div className="indian-election-phases-map-container">
            <svg ref={svgRef} style={{ background: 'transparent' }} viewBox="0 0 800 800" preserveAspectRatio="xMidYMid meet">
                <g />
            </svg>
        </div>
    );
};

export default MapChart;
