import React from 'react';
import { Link } from 'react-router-dom';  // Import Link component

const HeaderBlue = ({ title }) => {
    return (
        <header-blue>
            <h1>
                <Link to="/">Adnaan Jiwa</Link>
            </h1>
            <h3>{title}</h3>
        </header-blue>
    );
};

export default HeaderBlue;
