import React from 'react';
import '../Home.css';

const Bio = () => {
    return (
        <div className="bio">
            <div className="bio-text">
                <h2>Who am I?</h2>
                <p>
                    <b>Data-savvy developer interested in global politics.</b> Passionate about psychometrics, modeling,
                    forecasting. Skilled in stats, ML, and coding.
                </p>
            </div>
        </div>
    );
};

export default Bio;
