import React from 'react';
import './SortBar.css';

const SortBar = ({ onSort }) => {
    const handleChange = (event) => {
        onSort(event.target.value);
    };

    return (
        <select className="sort-bar" onChange={handleChange}>
            <option value="name-asc">Name: A-Z</option>
            <option value="name-desc">Name: Z-A</option>
            <option value="age-asc">Age: Low to High</option>
            <option value="age-desc">Age: High to Low</option>
        </select>
    );
};

export default SortBar;
