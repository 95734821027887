import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import IndianPowerProjection from "./pages/essays/IndianPowerProjection";
import PoliticsAndPsychometrics from "./pages/essays/PoliticsAndPsychometrics";
import MRP from "./pages/essays/mrp";
import EpsteinFlightMap from "./pages/epstein/EpsteinFlightMap";
import EpsteinContactList from "./pages/epstein/EpsteinContactList";
import EpsteinNetworkDiagram from "./pages/epstein/EpsteinNetworkDiagram";
import IndianElectionPhases from "./pages/IndianElectionPhases";
import GlobalViolence from "./pages/GlobalViolence"
import ElectionClusters from "./pages/ElectionClusters"
import BrexitVotes from "./pages/BrexitVotes"
import BillionaireNetworkDiagram from "./pages/BillionaireNetworkDiagram"
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import RedirectGithub from "./components/RedirectGithub";  // Temp redirect component
import './index.css';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="billionaire-network-diagram" element={<BillionaireNetworkDiagram />} />
                    {/*<Route path="brexit-votes" element={<BrexitVotes />} />*/}
                    <Route path="brexit-votes" element={<RedirectGithub />} />
                    <Route path="election-clusters" element={<ElectionClusters />} />
                    <Route path="global-violence" element={<GlobalViolence />} />
                    <Route path="essays/mrp" element={<MRP />} />
                    <Route path="indian-election-phases" element={<IndianElectionPhases />} />
                    <Route path="essays/indian-power-projection" element={<IndianPowerProjection />} />
                    <Route path="essays/politics-and-pychometrics" element={<PoliticsAndPsychometrics />} />
                    <Route path="epstein/flight-map" element={<EpsteinFlightMap />} />
                    <Route path="epstein/contact-list" element={<EpsteinContactList />} />
                    <Route path="epstein/network-diagram" element={<EpsteinNetworkDiagram />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);