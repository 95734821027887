import React, { useState } from 'react';
import Header from '../../components/Header';
import Essay from '../../components/Essay';
import Footer from '../../components/Footer';
import '../../Home.css';
import '../../fonts.css';

const IndianPowerProjection = () => {
    return (
        <div className="container">
            <Header title={'Indian Power Projection'}/>
            <Essay fileName="/text/indian-power-projection.json" />
            <Footer />
        </div>
    );
};

export default IndianPowerProjection;
