import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './PartyClusters.css';
import '../Home.css';
import '../fonts.css';

const PartyClusters = () => {
    const ref = useRef();

    const width = 1000;
    const height = 500;
    const colors = ["#0087dc", "#e4003b", "#faa61a", "#fed049", "#888888"];
    // and all other constants

    useEffect(() => {
        const width = 1000;
        const height = 500;
        const colors = [
            "#0087dc",
            "#e4003b",
            "#faa61a",
            "#fed049",
            "#888888",
        ];
        // Define fixed x-centre positions for each band
        const bandPositions = [
            width / 6,
            2 * width / 6,
            3 * width / 6,
            4 * width / 6,
            5 * width / 6,
        ];

        const clusterPositions = [
            width / 6 + 10,
            2 * width / 6 - 8 ,
            3 * width / 6 - 12,
            4 * width / 6 - 12,
            5 * width / 6 - 8,
        ];

        let clusterNames = ['Con', 'Lab', 'LD', 'PC/SNP', 'Other'];

        let clusterSequences = [
            { name: '1918', sizes: [39, 21, 26, 0, 14] }, // Conservative, Labour, Liberal Democrats, SNP, Others
            { name: '1922', sizes: [39, 30, 29, 0, 3] },
            { name: '1923', sizes: [38, 31, 30, 0, 2] },
            { name: '1924', sizes: [47, 33, 18, 0, 2] },
            { name: '1929', sizes: [38, 37, 24, 0, 1] },
            { name: '1931', sizes: [61, 31, 7, 0, 1] },
            { name: '1935', sizes: [53, 38, 7, 0, 2] },
            { name: '1945', sizes: [40, 48, 9, 0, 3] },
            { name: '1950', sizes: [43, 46, 9, 0, 1] },
            { name: '1951', sizes: [48, 49, 3, 0, 1] },
            { name: '1955', sizes: [50, 46, 3, 0, 1] },
            { name: '1959', sizes: [49, 44, 6, 0, 1] },
            { name: '1964', sizes: [43, 44, 11, 0, 1] },
            { name: '1966', sizes: [42, 48, 9, 1, 1] },
            { name: '1970', sizes: [46, 43, 8, 2, 1] },
            { name: '1974', sizes: [38, 37, 19, 3, 4] },
            { name: '1979', sizes: [44, 37, 14, 2, 3] },
            { name: '1983', sizes: [42, 28, 25, 2, 1] },
            { name: '1987', sizes: [42, 40, 23, 2, 3] },
            { name: '1992', sizes: [42, 34, 18, 2, 4] },
            { name: '1997', sizes: [31, 43, 17, 2, 7] },
            { name: '2001', sizes: [32, 41, 18, 2, 7] },
            { name: '2005', sizes: [32, 35, 22, 2, 9] },
            { name: '2010', sizes: [36, 29, 23, 2, 10] },
            { name: '2015', sizes: [37, 30, 8, 5, 20] },
            { name: '2017', sizes: [42, 40, 7, 4, 7] },
            { name: '2019', sizes: [44, 32, 12, 4, 8] }
        ];

        let clusterCounts = [];

        let sequenceIndex = 0;
        let clusterSizes = clusterSequences[sequenceIndex].sizes;

        const svg = d3.select("#viz");


        // Append labels
        svg.selectAll("text")
            .data(clusterNames)
            .enter()
            .append("text")
            .text((d) => d)
            .attr("x", (d, i) => bandPositions[i]) // position labels based on the band positions
            .attr("y", 150) // Adjust y position to reduce space
            .attr("text-anchor", "middle") // center the text
            .style("font-size", "20px")
            .style("fill", "#000");

        // Append year title in the middle at the top
        svg.append("text")
            .attr("id", "yearTitle")
            .text(clusterSequences[sequenceIndex].name)
            .attr("x", width / 2)
            .attr("y", 40) // Set year title y position to 20
            .attr("text-anchor", "middle")
            .style("font-size", "30px")
            .style("fill", "#000");

        // Function to calculate the differences in percentages between two years
        function calculateDifferences(previousSizes, currentSizes) {
            const totalPrevious = previousSizes.reduce((sum, size) => sum + size, 0);
            const totalCurrent = currentSizes.reduce((sum, size) => sum + size, 0);
            return currentSizes.map((size, i) => {
                const previousPercentage = (previousSizes[i] / totalPrevious) * 100;
                const currentPercentage = (size / totalCurrent) * 100;
                return (currentPercentage - previousPercentage).toFixed(0);
            });
        }

        let previousClusterSizes = clusterSizes.slice(); // Copy the initial cluster sizes

        function generateNodes(clusterSizes) {
            let nodes = [];
            clusterCounts = []; // Reset the cluster counts
            clusterSizes.forEach((size, i) => {
                clusterCounts[i] = size; // Update each cluster count
                nodes = nodes.concat(Array(size).fill().map(() => ({
                    band: i + 1,
                    x: clusterPositions[i],
                    y: height / 2,
                })));
            });
            return nodes;
        }


        let nodes = generateNodes(clusterSizes);

        // Add cluster counts beneath each cluster
        svg.selectAll(".countText")
            .data(clusterCounts)
            .enter()
            .append("text")
            .attr("class", "countText")
            .text(d => d)
            .attr("x", (d, i) => bandPositions[i])
            .attr("y", height - 150) // Reduce y position to reduce space
            .attr("text-anchor", "middle")
            .style("font-size", "16px") // Adjust font size
            .style("fill", "#000");

        let circles = svg
            .selectAll("circle")
            .data(nodes)
            .enter()
            .append("circle")
            .attr("r", 4)
            .attr("class", "circle")
            .style("fill", d => colors[d.band - 1])
            .style("stroke", d => colors[d.band - 1])
            .style("stroke-opacity", 0.2);

        let simulation = d3
            .forceSimulation(nodes)
            .velocityDecay(0.3)  // increased friction
            .alphaDecay(0.05) // slower cooling
            .force("charge", d3.forceManyBody().strength(-10))
            .force("collision", d3.forceCollide().radius(5).strength(1))
            .force("x", d3.forceX((d) => clusterPositions[d.band - 1]).strength(0.25)) // Corrected here
            .force("y", d3.forceY(height / 2).strength(0.25));

        simulation.on("tick", function () {
            circles
                .attr("cx", (d) => d.x)
                .attr("cy", (d) => d.y);
        });

        let clusterCircles = svg.selectAll(".cluster-circle")
            .data(clusterSizes)
            .enter()
            .append("circle")
            .attr("class", "cluster-circle")
            .attr("cx", (d, i) => bandPositions[i])
            .attr("cy", height / 2)
            .attr("r", d => Math.max(Math.sqrt(d) * 10, 30)) // Set a minimum of 30 for the radius
            .style("fill", (d, i) => colors[i])
            .style("stroke", (d, i) => colors[i])
            .style("stroke-width", 2);

        const scrubber = document.getElementById("yearScrubber");

        // Function to update the visualization based on the selected year
        function updateVisualization(selectedYearIndex) {
            sequenceIndex = selectedYearIndex;
            let clusterSizes2 = clusterSequences[sequenceIndex].sizes;

            // Remove the old year title
            d3.select("#yearTitle").remove();

            // Append a new year title
            svg.append("text")
                .attr("id", "yearTitle")
                .text(clusterSequences[sequenceIndex].name)
                .attr("x", width / 2)
                .attr("y", 40) // Set year title y position to 20
                .attr("text-anchor", "middle")
                .style("font-size", "30px")
                .style("fill", "#000");

            // Update the year title
            d3.select("#yearTitle").text(clusterSequences[sequenceIndex].name);

            // Calculate the differences in percentages
            let differences = calculateDifferences(previousClusterSizes, clusterSizes2);
            console.log(differences)
            let index = 0;
            clusterCounts = []; // Reset cluster counts
            clusterSizes2.forEach((size, i) => {
                clusterCounts[i] = size; // Update each cluster count
                for (let j = 0; j < size; j++) {
                    if (nodes[index]) {
                        nodes[index].band = i + 1;
                        index++;
                    }
                }
            });

            // Update the count texts with differences in percentages
            svg.selectAll(".countText")
                .data(clusterCounts)
                .text((d, i) => {
                    return `${d} (${(differences[i] > 0 ? '+' : '') + differences[i]}%)`;
                });

            // Store the current cluster sizes as previous for the next iteration
            previousClusterSizes = clusterSizes2.slice();
            // Update forceX only after updating the band property of nodes
            simulation
                .force("x", d3.forceX((d) => clusterPositions[d.band - 1]).strength(0.25)) // Use fixed band positions
                .force("y", d3.forceY(height / 2).strength(0.25))
                .alpha(0.8)
                .restart();

            circles // Re-select all circles
                .data(nodes) // Update the data
                .style("fill", d => colors[d.band - 1]); // Update the fill color based on the new band

            // Calculate the radius for each band and update cluster circles
            let bandRadii = clusterSizes2.map(size => Math.max(Math.sqrt(size) * 10, 30)); // Set a minimum of 30 for the radius
            svg.selectAll(".cluster-circle")
                .data(bandRadii)
                .attr("r", d => d);

            // Check if we have reached the end of the data
            if (sequenceIndex === clusterSequences.length - 1) {
                // If so, enable the 'play' button and disable the 'pause' button
                document.getElementById("play").disabled = false;
                document.getElementById("pause").disabled = true;
            }
        }

        // Add an event listener to the scrubber to handle the input change
        scrubber.addEventListener("input", function () {
            const selectedYearIndex = parseInt(this.value, 10);
            updateVisualization(selectedYearIndex);
        });

        // Get the play and pause buttons by their IDs
        const playButton = document.getElementById("play");
        const pauseButton = document.getElementById("pause");

        let isPlaying = false;
        let intervalId;

        // Function to start automatic scrubber scrolling
        function startPlaying() {
            isPlaying = true;
            playButton.disabled = true;
            pauseButton.disabled = false;

            intervalId = setInterval(function () {
                let nextIndex = (parseInt(scrubber.value) + 1) % clusterSequences.length;
                scrubber.value = nextIndex;
                updateVisualization(nextIndex);

                // Check if we have reached the end of the data
                if (nextIndex === 0) {
                    console.log('reset')
                    stopPlaying(); // Stop automatic scrolling and reset buttons
                    scrubber.value = 0; // Reset the scrubber to the beginning
                    updateVisualization(0); // Update the visualization to the beginning
                }
            }, 1000); // Set the interval time (in milliseconds) for automatic scrolling
        }

        // Function to stop automatic scrubber scrolling
        function stopPlaying() {
            isPlaying = false;
            playButton.disabled = false;
            pauseButton.disabled = true;

            clearInterval(intervalId);
        }

        // Add event listeners for play and pause buttons
        playButton.addEventListener("click", startPlaying);
        pauseButton.addEventListener("click", stopPlaying);

        // Update the scrubber label initially
        let nextSequenceName = clusterSequences[(sequenceIndex + 1) % clusterSequences.length].name;
        document.getElementById("update").innerText = 'Next';

        document.getElementById("update").addEventListener("click", function () {
            sequenceIndex = (sequenceIndex + 1) % clusterSequences.length;
            let clusterSizes2 = clusterSequences[sequenceIndex].sizes;

            // Update the scrubber value
            scrubber.value = sequenceIndex;

            // Call the updateVisualization function with the new scrubber value
            updateVisualization(sequenceIndex);

            // Remove the old year title
            d3.select("#yearTitle").remove();

            // Append a new year title
            svg.append("text")
                .attr("id", "yearTitle")
                .text(clusterSequences[sequenceIndex].name)
                .attr("x", width / 2)
                .attr("y", 40) // Set year title y position to 20
                .attr("text-anchor", "middle")
                .style("font-size", "30px")
                .style("fill", "#000");

            let nextSequenceName = clusterSequences[(sequenceIndex + 1) % clusterSequences.length].name;
            document.getElementById("update").innerText = 'Next';

            // Calculate the differences in percentages
            let differences = calculateDifferences(previousClusterSizes, clusterSizes2);

            // Update the year title
            d3.select("#yearTitle").text(clusterSequences[sequenceIndex].name);

            let index = 0;
            clusterCounts = []; // Reset cluster counts
            clusterSizes2.forEach((size, i) => {
                clusterCounts[i] = size; // Update each cluster count
                for (let j = 0; j < size; j++) {
                    if (nodes[index]) {
                        nodes[index].band = i + 1;
                        index++;
                    }
                }
            });

            // Update the count texts with differences in percentages
            svg.selectAll(".countText")
                .data(clusterCounts)
                .text((d, i) => {
                    return `${d} (${(differences[i] > 0 ? '+' : '') + differences[i]}%)`;
                });


            // Store the current cluster sizes as previous for the next iteration
            previousClusterSizes = clusterSizes2.slice();
            // Update forceX only after updating the band property of nodes
            simulation
                .force("x", d3.forceX((d) => clusterPositions[d.band - 1]).strength(0.25)) // Use fixed band positions
                .force("y", d3.forceY(height / 2).strength(0.25))
                .alpha(0.8)
                .restart();

            circles // Re-select all circles
                .data(nodes) // Update the data
                .style("fill", d => colors[d.band - 1]); // Update the fill color based on the new band

            // Calculate the radius for each band and update cluster circles
            let bandRadii = clusterSizes2.map(size => Math.max(Math.sqrt(size) * 10, 30)); // Set a minimum of 30 for the radius
            svg.selectAll(".cluster-circle")
                .data(bandRadii)
                .attr("r", d => d);
        });


    }, []);

    return (
        <div className="election-cluster-container">
            <svg ref={ref} id="viz" width="1000" height="500"></svg>

            <div id="controls">
                <input type="range" id="yearScrubber" min="0" max="25" step="1" value="0" />
                <button id="play">Play</button>
                <button id="pause" disabled>Pause</button>
                <button id="update">Next</button>
            </div>
        </div>
    );
};



export default PartyClusters;
