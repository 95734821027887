import React, { useEffect, useRef, useState } from 'react';
import { BsInfoCircle, BsX } from 'react-icons/bs'; // Import Bootstrap icons
import * as d3 from 'd3';
import './BillionaireNetworkDiagram.css';

const colorDefault = "rgba(42,42,42,0.29)";
const colorHighlight = "#1B998B";
const colorSecondary = "lightcoral";
const colorEpstein = "#054A29";
const colorFirstDegree = "rgba(39,70,144,0.85)"; // Customize the color for first-degree connections
const colorSecondDegree = "rgba(241,60,5,0.83)"; // Customize the color for second-degree connections

const NetworkDiagram = ({
                            data,
                            nodeColor,
                            nodeHighlightColor,
                            nodeFirstDegreeColor,
                            nodeSecondDegreeColor,
                            epsteinNodeColor,
                            linkColor,
                            linkHighlightColor,
                            defaultRadius,
                            firstDegreeNodeRadius,
                            secondDegreeNodeRadius,
                            showNameWellKnownScoreLimit,
                            zoomScaleExtent,
                            forceLinkDistance,
                            forceManyBodyStrength
                        }) => {

    const svgRef = useRef();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [hoveredName, setHoveredName] = useState("");
    const [hoveredDescription, setHoveredDescription] = useState("");
    const [showInfo, setShowInfo] = useState(false);

    // Define minRadius and maxRadius here
    const minRadius = 5;
    const maxRadius = 8;

    useEffect(() => {
        let nodesData = [];
        let linksData = [];

        // Store all people (links or regular people) with their descriptions
        let peopleDescriptions = {};

        // Central node
        // nodesData.push({ id: 'Jeffrey Epstein', group: 0, well_known_score: Infinity, fx: width / 2, fy: height / 2 });

        // Populate nodes, links, and descriptions
        for (let name in data) {
            const description = data[name].first_sentence;

            nodesData.push({
                id: name,
                group: 1,
                well_known_score: data[name].well_known_score,
                description: description
            });

            peopleDescriptions[name] = description;

            // linksData.push({ source: 'Jeffrey Epstein', target: name });

            data[name].links.forEach((link, index) => {
                if (!nodesData.find(d => d.id === link)) {
                    nodesData.push({ id: link, group: 2, well_known_score: 0 });
                }

                const linkDescription = data[name].link_first_sentences[index];
                linksData.push({
                    source: name,
                    target: link,
                    description: linkDescription
                });

                peopleDescriptions[link] = linkDescription;
            });
        }

        // Filter to only connected nodes
        const connectedNodes = nodesData.filter(node => {
            const sourceConnections = linksData.filter(link => link.source === node.id).length;
            const targetConnections = linksData.filter(link => link.target === node.id).length;
            return sourceConnections + targetConnections > 1;
        });

        const connectedLinks = linksData.filter(link =>
            connectedNodes.find(node => node.id === link.source) &&
            connectedNodes.find(node => node.id === link.target)
        );

        d3.select(svgRef.current).selectAll("*").remove();

        const svg = d3.select(svgRef.current);
        const svgWidth = +svg.attr("width");
        const svgHeight = +svg.attr("height");

        const color = d3.scaleOrdinal(d3.schemeCategory10);

        const collisionForce = d3.forceCollide().radius(function (d) {
            return d.group === 1 ? 40 : 30;
        });

        const simulation = d3.forceSimulation()
            .force("link", d3.forceLink().id(function (d) { return d.id; }).distance(30))
            .force("charge", d3.forceManyBody().strength(-500))
            .force("x", d3.forceX(width / 2).strength(0.12)) // Position nodes along the center initially
            .force("y", d3.forceY(height / 2).strength(0.12)) // Position nodes along the center initially
            .force("random", d3.forceManyBody().strength(5).distanceMax(800).theta(5)) // Additional force to push nodes apart initially
            .force("collision", collisionForce); // Add the collision force to the simulation


        const zoom = d3.zoom()
            .scaleExtent([0.1, 10])
            .on("zoom", zoomed);

        svg.call(zoom);

        const container = svg.append("g");

        const link = container.append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(connectedLinks)
            .enter().append("line")
            .attr("stroke", colorDefault)
            .attr("stroke-width", 0.3);

        const radiusScale = d3.scaleLinear()
            .domain([0, d3.max(connectedNodes, d => d.group === 1 ? d.well_known_score + 1 : 0)])
            .range([minRadius, maxRadius]);

        const node = container.append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            .data(connectedNodes)
            .enter().append("circle")
            .attr("r", d => d.group === 1 ? 8 : 5) // Increase size for first degree connections
            .attr("fill", function (d) {
                if (d.id === "Jeffrey Epstein") {
                    return colorEpstein;
                } else if (d.group === 1) {
                    return colorFirstDegree; // Set custom color for first-degree connections
                } else if (d.group === 2) {
                    return colorSecondDegree; // Set custom color for second-degree connections
                } else {
                    return color(d.group);
                }
            })
            .on("mouseover", handleMouseOver)
            .on("mouseout", handleMouseOut)
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));

        const labels = container.append("g")
            .attr("class", "labels")
            .selectAll("text")
            .data(connectedNodes)
            .enter().append("text")
            .attr("class", "label")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "hanging")
            .attr("fill", "black")
            .text(function (d) {
                return d.well_known_score > 100000 ? d.id : "";
            });

        simulation
            .nodes(connectedNodes)
            .on("tick", ticked);

        simulation.force("link")
            .links(connectedLinks);

        function ticked() {
            link
                .attr("x1", function (d) {
                    return d.source.x;
                })
                .attr("y1", function (d) {
                    return d.source.y;
                })
                .attr("x2", function (d) {
                    return d.target.x;
                })
                .attr("y2", function (d) {
                    return d.target.y;
                });

            node
                .attr("cx", function (d) {
                    return d.x;
                })
                .attr("cy", function (d) {
                    return d.y;
                });

            labels
                .attr("x", function (d) {
                    return d.x;
                })
                .attr("y", function (d) {
                    return d.y + 10;
                });

            node.attr("cx", function (d) { return d.x; })
                .attr("cy", function (d) { return d.y; })
        }

        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.5).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        function handleMouseOver(event, d) {
            // Customize the hover effect as desired
            setHoveredName(d.id);
            setHoveredDescription(peopleDescriptions[d.id]);

            d3.select(this)
                .attr("r", radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.5)
                .attr("fill", colorHighlight);

            const firstDegreeConnections = connectedLinks.filter(
                (link) => link.source === d || link.target === d
            );

            link
                .filter((link) => firstDegreeConnections.includes(link))
                .attr("stroke", colorHighlight)
                .attr("stroke-width", 1);


            node
                .filter((node) =>
                    firstDegreeConnections.some(
                        (link) =>
                            link.source === d && link.target === node ||
                            link.target === d && link.source === node
                    )
                )
                .attr("fill", colorHighlight)
                .attr("r", d => radiusScale(d.group === 1 ? d.well_known_score + 1 : 0) * 1.2);

            labels
                .filter((node) => {
                    const isSourceConnected = firstDegreeConnections.some((link) => link.source === d && link.target === node);
                    const isTargetConnected = firstDegreeConnections.some((link) => link.source === node && link.target === d);
                    return node.id === d.id || isSourceConnected || isTargetConnected;
                })
                .text(function (node) {
                    return node.id;
                })
                .style("visibility", "visible");
        }

        function handleMouseOut(event, d) {
            setHoveredName('');
            setHoveredDescription('');

            d3.select(this)
                .attr("r", d => d.group === 1 ? 8 : 5) // Increase size for first degree connections
                .attr("fill", function (d) {
                    if (d.id === "Jeffrey Epstein") {
                        return colorEpstein;
                    } else if (d.group === 1) {
                        return colorFirstDegree;
                    } else if (d.group === 2) {
                        return colorSecondDegree;
                    } else {
                        return color(d.group);
                    }
                });


            link
                .attr("stroke", colorDefault)
                .attr("stroke-width", 0.3);

            labels
                .filter((node) => node.id === d.id && node.well_known_score <= 100000)
                .text("")
                .style("visibility", "hidden");

            node
                .filter((node) =>
                    connectedLinks.some(
                        (link) =>
                            (link.source === d && link.target === node) ||
                            (link.source === node && link.target === d)
                    )
                )
                .attr("fill", function (d) {
                    if (d.id === "Jeffrey Epstein") {
                        return colorEpstein;
                    } else if (d.group === 1) {
                        return colorFirstDegree;
                    } else if (d.group === 2) {
                        return colorSecondDegree;
                    } else {
                        return color(d.group);
                    }
                })
                .attr("r", d => d.group === 1 ? 8 : 5) // Increase size for first degree connections

            labels
                .filter((node) => {
                    const isSourceConnected = connectedLinks.some((link) => link.source === d && link.target === node);
                    const isTargetConnected = connectedLinks.some((link) => link.source === node && link.target === d);
                    return isSourceConnected || isTargetConnected;
                })
                .style("visibility", function (node) {
                    return node.well_known_score > 100000 ? "visible" : "hidden";
                });
        }

        function zoomed(event) {
            container.attr("transform", event.transform);
        }
    }, [data]);

    const handleInfoClick = () => {
        setShowInfo(true);
    };

    const handleCloseClick = () => {
        setShowInfo(false);
    };

    return (
        <div className="billionaire-network-diagram-div">
            <div id="title">Billionaire Network Diagram</div>
            <svg ref={svgRef} width={width} height={height}></svg>
            {hoveredName && (
                <div className="bottom-right-div">
                    <div className="hovered-name">{hoveredName}</div>
                    <div className="hovered-description">{hoveredDescription}</div>
                </div>
            )}
            {showInfo && (
                <div className="info-container">
                    <div className="info-content">
                        <div className="info-header">
                            <h3>Project Information</h3>
                            <button className="close-button" onClick={handleCloseClick}>
                                <BsX />
                            </button>
                        </div>
                        <div className="info-body">
                            <p>
                                <strong>This interactive visualization showcases the connections among the world's billionaires through links on their Wikipedia pages. Here's what you need to know and how you can use it:</strong>
                            </p>
                            <ol>
                                <li>
                                    <strong>Network Visualization:</strong> The main feature of this visualization is the network diagram itself. It depicts billionaires as nodes, and the links between them represent the connections extracted from their Wikipedia pages. The size and color of the nodes correspond to their importance and the number of connections they have.
                                </li>
                                <li>
                                    <strong>Node Interaction:</strong> When you hover over a billionaire's node, additional information will appear, including their name and the first sentence from their Wikipedia page. This feature enables you to quickly gather information about specific billionaires in the network.
                                </li>
                                <li>
                                    <strong>Highlighting Connections:</strong> By interacting with the nodes, you can highlight their connections. When you hover over a billionaire, all their first-degree connections (billionaires directly linked to them) will be distinguished by a different color and thicker links. This facilitates the understanding of immediate relationships and connections within the billionaire network.
                                </li>
                                <li>
                                    <strong>Zooming and Panning:</strong> The visualization offers zooming capabilities, allowing you to examine details or obtain an overview of the entire billionaire network. You can use the scroll wheel or touch gestures to zoom in and out. Additionally, you can click and drag the diagram to pan and explore different areas of the network.
                                </li>
                                <li>
                                    <strong>Responsive Design:</strong> The webpage is designed to adapt seamlessly to various screen sizes and devices. Whether you're using a desktop, laptop, tablet, or smartphone, you can comfortably view and interact with the billionaire network diagram.
                                </li>
                            </ol>
                            <p>
                                <em>The Billionaire Network Diagram</em> was constructed using a combination of D3 network visualization and a Python script. The Python script scrapes data from Wikipedia, extracting the names of billionaires, the first sentences of their Wikipedia pages, and identifying other billionaires mentioned on the pages, thus expanding the network.
                            </p>
                            <p>
                                The scraped data serves as input for the React component responsible for visualizing the billionaire network diagram. The React component leverages the D3 library to render the network diagram based on the retrieved data. Each node represents a billionaire, and the links represent the connections derived from their Wikipedia pages. The node size and color reflect their importance and connection degrees. Interactions such as hovering over nodes and highlighting connections are enabled to enhance exploration and understanding of the billionaire network.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="info-icon-container" onClick={handleInfoClick}>
                <BsInfoCircle className="info-icon" />
            </div>
        </div>
    );
};

export default NetworkDiagram;
