import React from 'react';
import './PersonPanel.css';

const PersonPanel = ({ person, onClose }) => {
    return (
        <div className="person-panel">
            <button className="close-button" onClick={onClose}>&times;</button>
            <h3 className="person-name">{person.name}</h3>
            <p className="person-bio-panel">{person.bio}</p>
        </div>
    );
};

export default PersonPanel;
