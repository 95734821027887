// RedirectGithub.js
import React from 'react';

function RedirectGithub() {
    React.useEffect(() => {
        window.location.href = "https://a-jiwa.github.io/Brexit-votes/";
    }, []);

    return null;
}

export default RedirectGithub;
