import React from 'react';
import ProjectItem from './ProjectItem';
import '../Home.css';

const Projects = ({ projects }) => {
    return (
        <section className="projects">
            <div className="projects-container"> {/* Add this div */}
                <div className="projects-header">
                    <h2>Projects</h2>
                </div>
                <div className="projects-list">
                    {projects.map((project, index) => (
                        <ProjectItem key={index} project={project} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
