import React from 'react';
import './NoPage.css';

const NoPage = () => {
    return (
        <body>
        <div className="contant_box_404">
            <h3 className="h2">
                404
            </h3>
            <p>This page isn't ready yet.</p>
            <a href="/" className="link_404">Go to Home</a>
        </div>
        </body>
    );
};


export default NoPage;