import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import '../components/PartyClusters.css';
import PartyClusters from '../components/PartyClusters';
import Header from "../components/Header";
function ElectionClusters() {
  return (
      <div className="election-clusters-app">
          <Header title={'Election Clusters'} />
          <PartyClusters />
      </div>
  );
}

export default ElectionClusters;
     