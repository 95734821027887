import React, { useState, useEffect } from 'react';
import '../Home.css';

const Essay = ({ fileName }) => {
    const [essayData, setEssayData] = useState(null);

    useEffect(() => {
        console.log(fileName);
        // Fetch essay data when the component mounts
        fetch(fileName)
            .then(response => response.json())
            .then(data => setEssayData(data))
            .catch(console.error);
    }, [fileName]);

    if (!essayData) {
        // Data has not yet been loaded
        return <div>Loading...</div>;
    }

    return (
        <div className="essay">
            <div className="essay-title">
                <h2>{essayData.title}</h2>
            </div>
            <div className="essay-text">
                {essayData.text.map((paragraph, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                ))}
            </div>
        </div>
    );
};

export default Essay;
