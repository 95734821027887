import React from 'react';
import { Link } from 'react-router-dom';  // Import Link component

const HeaderDark = ({ title }) => {
    return (
        <header-dark>
            <h1>
                <Link to="/">  {/* Move Link here */}
                    Adnaan Jiwa
                </Link>
            </h1>
            <h3>{title}</h3>
        </header-dark>
    );
};

export default HeaderDark;
