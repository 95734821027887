import React, {useState, useEffect} from 'react';
import Header from '../../components/Header';
import PersonCard from '../../components/PersonCard';
import PersonPanel from '../../components/PersonPanel';
import SearchBar from '../../components/SearchBar';
import SortBar from '../../components/SortBar';
import peopleData from '../../data/PeopleData';
import '../../components/EpsteinContactList.css';
import '../../Home.css';
import '../../fonts.css';

const EpsteinContactList = () => {
    const [people, setPeople] = useState(peopleData);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOption, setSortOption] = useState('name-asc');
    const [showOnlyWithBio, setShowOnlyWithBio] = useState(true);
    const [selectedPerson, setSelectedPerson] = useState(null);

    const handleSelectPerson = (person) => {
        setSelectedPerson(person);
    };

    const handleClosePanel = () => {
        setSelectedPerson(null);
    };

    const [showInfo, setShowInfo] = useState(false);

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    useEffect(() => {
        const filteredAndSorted = peopleData
            .filter((person) =>
                person.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .filter((person) => !showOnlyWithBio || (showOnlyWithBio && person.bio))
            .sort((a, b) => {
                switch (sortOption) {
                    case 'name-asc':
                        return a.name.localeCompare(b.name);
                    case 'name-desc':
                        return b.name.localeCompare(a.name);
                    case 'age-asc':
                        return a.age - b.age;
                    case 'age-desc':
                        return b.age - a.age;
                    default:
                        return 0;
                }
            });

        setPeople(filteredAndSorted);
    }, [searchTerm, sortOption, showOnlyWithBio]);

    return (
        <div className="container">
            <Header title={'Epstein Network Diagram'} />
            <div className={`app${selectedPerson ? ' with-panel' : ''}`}>
            {/*<div className="fixed-header">*/}
            {/*    <h1>Epstein's Nexus</h1>*/}
            {/*    <button className="info-button" onClick={toggleInfo}>?</button>*/}
            {/*</div>*/}
            {/*{showInfo && <InfoOverlay onClose={toggleInfo}/>}*/}
            {selectedPerson && <PersonPanel person={selectedPerson} onClose={handleClosePanel}/>}
            <div className="app-content">
                <header className="app-header">
                    <SortBar onSort={setSortOption}/>
                    <SearchBar onSearch={setSearchTerm}/>
                    <label className="app-label">
                        <input
                            type="checkbox"
                            checked={showOnlyWithBio}
                            onChange={(e) => setShowOnlyWithBio(e.target.checked)}
                        />
                        Only with bio
                    </label>
                </header>
                <main className="person-grid">
                    {people.map((person) => (
                        <PersonCard key={person.id} person={person} onSelect={handleSelectPerson}/>
                    ))}
                </main>
            </div>
        </div>
        </div>
    );
};
export default EpsteinContactList;
