import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = 'pk.eyJ1IjoiYWRuYWFuaiIsImEiOiJjbGs2d2E1Y3owM28xM2psaXVxd2xja2J6In0.nkC0bsM3cuGc4Svt9CZaLg';

const GlobalViolenceMap = () => {
    const legendRef = useRef(null);
    const [legendVisible, setLegendVisible] = useState(false);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/dark-v11',
            center: [0, 20],
            zoom: 1.8,
            attributionControl: false,
        });

        map.on('load', () => {
            loadMapData(map);
        });

        // map.on('zoom', () => {
        //     console.log(map.getZoom())
        // })
        
        return () => {
            map.remove();
        };
    }, []);

    useEffect(() => {
        if (legendRef.current) {
            createLegend();
        }
    }, []);

    const loadMapData = (map) => {
        const urls = [
            'https://a-jiwa.github.io/global-violence-data/2022-Violence-Africa.geojson',
            'https://a-jiwa.github.io/global-violence-data/2022-Violence-Antarctica-Oceania.geojson',
            'https://a-jiwa.github.io/global-violence-data/2022-Violence-Asia.geojson',
            'https://a-jiwa.github.io/global-violence-data/2022-Violence-Europe.geojson',
            'https://a-jiwa.github.io/global-violence-data/2022-Violence-North-America.geojson',
            'https://a-jiwa.github.io/global-violence-data/2022-Violence-South-America.geojson'
        ];

        Promise.all(urls.map(url => fetch(url).then(response => response.json())))
            .then(dataArray => {
                dataArray.forEach((data, index) => {
                    const sourceId = `region${index + 1}Violence`;
                    const layerId = `violencePoints${index + 1}`;

                    map.addSource(sourceId, {
                        type: 'geojson',
                        data: data
                    });

                    map.addLayer({
                        id: layerId,
                        type: 'circle',
                        source: sourceId,
                        paint: {
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                2, 0.5, // Set the circle radius to 0.5 when zoom level is 0
                                // 12, 1.5, // Increase the radius to 1.5 when zoom level is 10
                                12, 3 // Increase the radius to 2 when zoom level is 20
                            ],
                            'circle-color': [
                                'match',
                                ['get', 'sub_event_type'],
                                'Abduction/forced disappearance',
                                '#FF0000',
                                'Armed clash',
                                '#B22222',
                                'Attack',
                                '#8B0000',
                                'Mob violence',
                                '#FF4500',
                                'Sexual violence',
                                '#D2691E',
                                'Remote explosive/landmine/IED',
                                '#CD853F',
                                'Grenade',
                                '#DDA0DD',
                                'Looting/property destruction',
                                '#FFD700',
                                'Disrupted weapons use',
                                '#ADFF2F',
                                'Violent demonstration',
                                '#32CD32',
                                'Arrests',
                                '#7CFC00',
                                'Protest with intervention',
                                '#00FF00',
                                'Change to group/activity',
                                '#7FFF00',
                                'Agreement',
                                '#00FA9A',
                                'Peaceful protest',
                                '#98FB98',
                                'Air/drone strike',
                                '#7F007F',
                                'Chemical weapon',
                                '#8B4513',
                                'Excessive force against protesters',
                                '#800000',
                                'Government regains territory',
                                '#00CED1',
                                'Headquarters or base established',
                                '#008B8B',
                                'Non-state actor overtakes territory',
                                '#BDB76B',
                                'Non-violent transfer of territory',
                                '#556B2F',
                                'Shelling/artillery/missile attack',
                                '#B22222',
                                'Suicide bomb',
                                '#8B0000',
                                'Other',
                                '#F0FFF0',
                                '#AAA'
                            ],
                            'circle-opacity': [
                                'match',
                                ['get', 'sub_event_type'],
                                'Abduction/forced disappearance',
                                1,
                                'Armed clash',
                                0.9,
                                'Attack',
                                0.9,
                                'Mob violence',
                                0.8,
                                'Sexual violence',
                                1,
                                'Remote explosive/landmine/IED',
                                0.8,
                                'Grenade',
                                0.6,
                                'Looting/property destruction',
                                0.7,
                                'Disrupted weapons use',
                                0.6,
                                'Violent demonstration',
                                0.7,
                                'Arrests',
                                0.5,
                                'Protest with intervention',
                                0.5,
                                'Change to group/activity',
                                0.5,
                                'Agreement',
                                0.5,
                                'Peaceful protest',
                                0.5,
                                'Air/drone strike',
                                0.9,
                                'Chemical weapon',
                                0.9,
                                'Excessive force against protesters',
                                0.9,
                                'Government regains territory',
                                0.2,
                                'Headquarters or base established',
                                0.3,
                                'Non-state actor overtakes territory',
                                0.8,
                                'Non-violent transfer of territory',
                                0.3,
                                'Shelling/artillery/missile attack',
                                0.9,
                                'Suicide bomb',
                                0.9,
                                'Other',
                                0.5,
                                0.5
                            ]
                        }
                    });
                });
            });
    };

    const createLegend = () => {
        const types = ['Abduction/forced disappearance', 'Armed clash', 'Attack', 'Mob violence', 'Sexual violence', 'Remote explosive/landmine/IED', 'Grenade', 'Looting/property destruction', 'Disrupted weapons use', 'Violent demonstration', 'Arrests', 'Protest with intervention', 'Change to group/activity', 'Agreement', 'Peaceful protest', 'Air/drone strike', 'Chemical weapon', 'Excessive force against protesters', 'Government regains territory', 'Headquarters or base established', 'Non-state actor overtakes territory', 'Non-violent transfer of territory', 'Shelling/artillery/missile attack', 'Suicide bomb', 'Other'];
        const colors = ['#FF0000', '#B22222', '#8B0000', '#FF4500', '#D2691E', '#CD853F', '#DDA0DD', '#FFD700', '#ADFF2F', '#32CD32', '#7CFC00', '#00FF00', '#7FFF00', '#00FA9A', '#98FB98', '#7F007F', '#8B4513', '#800000', '#00CED1', '#008B8B', '#BDB76B', '#556B2F', '#B22222', '#8B0000', '#F0FFF0'];

        types.forEach((type, i) => {
            const color = colors[i];
            const item = document.createElement('div');
            const key = document.createElement('span');
            key.className = 'legend-key';
            key.style.backgroundColor = color;
            const value = document.createElement('span');
            value.className = 'legend-value'; // Update the class name to 'legend-value'
            value.innerHTML = type;
            item.appendChild(key);
            item.appendChild(value);
            legendRef.current.appendChild(item); // Use the useRef hook to access the legend element
        });
    };

    const toggleLegend = () => {
        setLegendVisible(!legendVisible);
    };

    return (
        <>
            <div id="title">Political Violence in 2022</div>
            <div id="map"></div>
            <div id="legend" className={`mapboxgl-ctrl mapboxgl-ctrl-group ${legendVisible ? 'show' : 'hide'}`}
                 ref={legendRef}></div>

            <button id="toggleLegend" onClick={toggleLegend}>
                {legendVisible ? 'Hide' : 'Legend'}
            </button>

            <div id="attribution" style={{position: 'absolute', bottom: '10px', right: '10px', zIndex: '1'}}>
                Data provided by <a href="https://acleddata.com/" target="_blank">ACLED</a>
            </div>
        </>
    );
};

export default GlobalViolenceMap;
