import { Outlet, Link } from "react-router-dom";
import useScrollToTop from '../components/useScrollToTop'; // Import your custom hook

const Layout = () => {
    // useScrollToTop(); // Call your custom hook

    return (
        <>
            <Outlet />
        </>
    )
};

export default Layout;