import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ title }) => {
    return (
        <header>
            <h1>
                <Link to="/">Adnaan Jiwa</Link>
            </h1>
            <h3>{title}</h3>
        </header>
    );
};

export default Header;
