// Importing necessary dependencies and components
import React, { useState } from 'react';
import Scrolly from '../components/IndianElectionPhasesScrollama';
import MapChart from '../components/IndianElectionPhasesMapChart';
import Header from '../components/Header';
import { essayContent } from '../components/IndianElectionPhasesEssayContent';
import '../components/IndianElectionPhases.css';
import '../fonts.css';

// Defining the main App component
const IndianElectionPhases = () => {
    // Setting up state for the active step
    const [activeStep, setActiveStep] = useState(0);

    // Function to handle step change
    const handleStepChange = stepIndex => setActiveStep(stepIndex);

    // Rendering the App component
    return (
        <div className="indian-election-phases-App">
            <Header title={'Indian Election Phases'} />
            <div className="indian-election-phases-titleContainer">
                {/* Displaying the phase based on the active step */}
                <h1>{essayContent[activeStep].phase}</h1>
            </div>
            <div className="indian-election-phases-ContentContainer">
                {/* Rendering the Scrolly component with essay steps and handling step change */}
                <Scrolly steps={essayContent} onStepChange={handleStepChange} />
                {/* Rendering the MapChart component with active step and essay content */}
                <MapChart activeStep={activeStep} essayContent={essayContent} />
            </div>
        </div>
    );
}

// Exporting the App component as the default export
export default IndianElectionPhases;
