import React, { useEffect, useState } from 'react';
import HeaderBlue from '../../components/HeaderBlue';
import '../../components/EpsteinNetworkDiagram.css';
import '../../Home.css';
import '../../fonts.css';
import * as d3 from 'd3';
import NetworkDiagram from '../../components/NetworkDiagram';
import Header from "../../components/Header";

const EpsteinNetworkDiagram = () => {
    console.log('EpsteinNetworkDiagram')
    const [data, setData] = useState({});

    useEffect(() => {
        // Fetch local data.json file
        fetch('/EpsteinNetworkDiagramData.json')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error loading data:', error));
    }, []);

    return (
        <div>
            <HeaderBlue title={'Epstein Network Diagram'} />
            <NetworkDiagram data={data} />
        </div>
    );
};

export default EpsteinNetworkDiagram;
