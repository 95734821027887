// Importing necessary dependencies and components
import React from 'react';
import { Scrollama, Step } from 'react-scrollama';

// Defining the Scrolly component
const Scrolly = ({ steps, onStepChange }) => {
    // Function to handle step enter event
    const handleStepEnter = ({ data }) => onStepChange(data);

    // Rendering the Scrolly component
    return (
        <div className="indian-election-phases-steps">
            {/* Initializing the Scrollama component */}
            <Scrollama onStepEnter={handleStepEnter} offset={0.33}>
                {/* Mapping over the steps and rendering each step */}
                {steps.map((step, stepIndex) => (
                    <Step data={stepIndex} key={stepIndex}>
                        <div className="indian-election-phases-step">
                            <div className="indian-election-phases-step-text">
                                <p>{step.text}</p>
                            </div>
                        </div>
                    </Step>
                ))}
            </Scrollama>
        </div>
    );
}

// Exporting the Scrolly component as the default export
export default Scrolly;