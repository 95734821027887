import React, { useState } from 'react';
import Header from '../../components/Header';
import Essay from '../../components/Essay';
import Footer from '../../components/Footer';
import '../../Home.css';
import '../../fonts.css';

const MRP = () => {
    return (
        <div className="container">
            <Header title={'Understanding MRPs'}/>
            <Essay fileName="/text/mrp.json" />
            <Footer />
        </div>
    );
};

export default MRP;
