export const essayContent = [
    {
        phase: "Introduction",
        text: "The 2019 Indian General Election: a momentous occasion that shaped the destiny of a nation. While it offered hope, anticipation, and the promise of change, it also bore witness to an unsettling undercurrent that stained the journey to democracy. It is a tale of political violence that shifted and surged, echoing the ebb and flow of democracy itself.\n" +
            "\n" +
            "As the electoral battle traversed the vast expanse of India, so did the dark shadows of conflict. This page takes you on a journey through these narratives, connecting the dots that outline a map of turbulence, revealing how violence moved with the pulse of the election. Through this exploration, we will gain a deeper understanding of the violence that marred the 2019 Indian election, shedding light on its implications for the democratic fabric of the nation."},
    {
        phase: "Phase One",
        text: "Phase One began in areas like Andhra Pradesh and parts of the Northeast, each with distinctive ethnic and tribal demographics. The BJP focused on local issues and welfare schemes, such as the PM KISAN scheme and Jan Dhan Yojana, highlighting the financial inclusion they have brought.",
    },
    {
        phase: "Phase Two",
        text: "Phase Two saw BJP forming a strategic alliance with the AIADMK in Tamil Nadu. The party sought to consolidate the Hindu vote bank, and in response to the recent Pulwama attack, they highlighted their tough stance on national security to gain support among the state's considerable population of veterans and military personnel.",
    },
    {
        phase: "Phase Three",
        text: "During Phase Three, in the diverse states of Gujarat, Kerala, and West Bengal, the BJP brought its national security narrative to the forefront, capitalizing on the heightened nationalist sentiments following the Balakot airstrikes in response to the Pulwama attack.",
    },
    {
        phase: "Phase Four",
        text: "In Phase Four, in states like Rajasthan and Madhya Pradesh with substantial veteran populations, the BJP highlighted their robust response to Pakistan's aggression, while also promising better facilities for servicemen, appealing directly to this demographic.",
    },
    {
        phase: "Phase Five",
        text: "During the Fifth Phase in Uttar Pradesh, the BJP utilized the demographics, effectively countering the SP-BSP alliance that aimed to consolidate Dalit and Yadav votes. They highlighted their policies aimed at Dalits and OBCs, such as reservation in government jobs and educational institutions.",
    },
    {
        phase: "Phase Six",
        text: "In Phase Six, in Delhi, the BJP capitalized on its central schemes like Ujjwala Yojana, affordable housing, and Swachh Bharat Abhiyan, demonstrating their appeal to the urban middle class and the poor."
    },
    {
        phase: "Final Phase",
        text: "In the Final Phase in West Bengal, the BJP skillfully navigated the religious and cultural demographics. They used incidents of violence to highlight the alleged failure of the TMC government, while also stoking religious tensions, positioning themselves as protectors of Hindu rights and traditions.",
    },
    {
        phase: "Conclusion",
        text: "Throughout the phases, the BJP exhibited an astute understanding of India's demographic and political landscape. Their narratives around national security, development, and cultural identity effectively resonated with a broad section of the populace, ultimately leading them to a remarkable victory.",
    }
];
