import React, { useState } from 'react';
import Header from '../../components/Header';
import Essay from '../../components/Essay';
import Footer from '../../components/Footer';
import '../../Home.css';
import '../../fonts.css';

const PoliticsAndPsychometrics = () => {
    return (
        <div className="container">
            <Header title={'Politics & Psychometrics'}/>
            <Essay fileName="/text/politics-and-psychometrics.json" />
            <Footer />
        </div>
    );
};

export default PoliticsAndPsychometrics;
