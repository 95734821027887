import { Link } from 'react-router-dom';

const ProjectItem = ({ project }) => {
    let projectTypeClassName = '';

    // Set the class name based on the project type
    switch (project.type) {
        case 'Visualisation':
            projectTypeClassName = 'project-type-visualisation';
            break;
        case 'Investigation':
          projectTypeClassName = 'project-type-investigation';
          break;
        case 'Essay':
          projectTypeClassName = 'project-type-essay';
          break;
        case 'Review':
            projectTypeClassName = 'project-type-review';
            break;
        default:
            // Default class name if no match is found
            projectTypeClassName = 'project-type-default';
    }

    if (project.link) {
        return (
            <Link to={project.link} className="project-item">
                <b className={`project-type project-type-${project.type}`}>{project.type}</b>
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                <span className="arrow-icon">&rarr;</span>
            </Link>
        );
    } else {
        return (
            <div className="project-item">
                <p className={`project-type ${projectTypeClassName}`}>{project.type}</p>
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                <span className="arrow-icon">&rarr;</span>
            </div>
        );
    }
};

export default ProjectItem;
