import React from 'react';
import '../components/GlobalViolence.css';
import GlobalViolenceMap from '../components/GlobalViolenceMap';
import HeaderDark from "../components/HeaderDark";
import Header from "../components/Header";

const GlobalViolence = () => {
  return (
      <div className="global-violence-app">
          <HeaderDark title={'Global Political Violence'} />
          <GlobalViolenceMap />
      </div>
  );
};

export default GlobalViolence;
