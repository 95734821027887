import React from 'react';
import '../Home.css';

const FlightMap = () => {
    return (
        <div className="map">
            <iframe
                src="/flight_paths.html"
                style={{width: '100%', height: '100%', border: 'none'}}
                title="Flights">
            </iframe>
        </div>
    );
};

export default FlightMap;
