import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import '../components/BillionaireNetworkDiagram.css';
import '../Home.css';
import '../fonts.css';
import * as d3 from 'd3';
import NetworkDiagram from '../components/BillionaireNetworkDiagram';

const EpsteinNetworkDiagram = () => {
    const [data, setData] = useState({});

    // Initialization of all the required props
    const nodeColor = "#1f77b4";
    const nodeHighlightColor = "#ff7f0e";
    const nodeFirstDegreeColor = "#2ca02c";
    const nodeSecondDegreeColor = "#d62728";
    const epsteinNodeColor = "#9467bd";
    const linkColor = "#999";
    const linkHighlightColor = "#ff7f0e";
    const defaultRadius = 5;
    const firstDegreeNodeRadius = 10;
    const secondDegreeNodeRadius = 7;
    const showNameWellKnownScoreLimit = 2;
    const zoomScaleExtent = [1, 8];
    const forceLinkDistance = 30;
    const forceManyBodyStrength = -200;

    useEffect(() => {
        // Fetch local data.json file
        fetch('/BillionaireNetworkDiagramData.json')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error loading data:', error));
    }, []);

    return (
        <div>
            <Header title={'Billionaire Network Diagram'} />
            <NetworkDiagram
                data={data}
                nodeColor={nodeColor}
                nodeHighlightColor={nodeHighlightColor}
                nodeFirstDegreeColor={nodeFirstDegreeColor}
                nodeSecondDegreeColor={nodeSecondDegreeColor}
                epsteinNodeColor={epsteinNodeColor}
                linkColor={linkColor}
                linkHighlightColor={linkHighlightColor}
                defaultRadius={defaultRadius}
                firstDegreeNodeRadius={firstDegreeNodeRadius}
                secondDegreeNodeRadius={secondDegreeNodeRadius}
                showNameWellKnownScoreLimit={showNameWellKnownScoreLimit}
                zoomScaleExtent={zoomScaleExtent}
                forceLinkDistance={forceLinkDistance}
                forceManyBodyStrength={forceManyBodyStrength}
            />
        </div>
    );
};

export default EpsteinNetworkDiagram;
