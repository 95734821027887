import React from 'react';
import './PersonCard.css';

const truncateBio = (bio, maxLength = 500) => {
    return bio.length > maxLength ? bio.slice(0, maxLength) + '...' : bio;
};

const PersonCard = ({ person, onSelect }) => {
    return (
        <div className="person-card" onClick={() => onSelect(person)}>
            <h3 className="person-name">{person.name}</h3>
            {/*<p className="person-age">Age: {person.age}</p>*/}
            <p className="person-bio">{truncateBio(person.bio)}</p>
        </div>
    );
};

export default PersonCard;
